.join-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.team-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.team-image {
    width: 35%;
}

.team-image img {
    width: 80%;
    height: auto;
    object-fit: cover;
    float: left;
}

.join-our-team-content {
    width: 65%;
}

.join-our-team-content p {
    text-align: justify;
}

.no-positions{
    font-size: 1.6rem;
    color: white;
}

.join-our-team-header {
    text-align: left;
    color: #fff;
    margin-right: 10%;
}

.position {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-right: 10%;
}
  
.position-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
  
.arrow.down {
    transform: rotate(0);
}
  
.arrow.up {
    transform: rotate(180deg);
}
  
.position-description {
    display: none;
    text-align: justify;
}
  
.position.active .position-description {
    display: block;
}

.ContactUsButton{
    border-color: #039be5;
    background-color: #fff;
    color: #039be5;
    border-width: 2px;
    padding: 20px;
    font-size: 1.25rem;
    cursor: pointer;
}

.water-image img {
    width: 100%;
    height: auto;
    display: block;
}