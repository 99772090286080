.our-process {
    color: #fff;
    margin:  0 5% 0 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.our-process-content {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.process-flex-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
}
  
h1 {
    font-size: 4rem;
    text-align: left;
    flex-basis: 100%;
}

.external-process-icons{
    display: none;
    opacity: 0.5;
}

.process-icons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 5%;
}

.process-icons span{
    display: flex;
    margin-left: 0.8%;
    justify-content: center;
    align-self: center;
}

.process-icons img {
    width: 30px;
    height: auto;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.external-process-icons img:hover,
.external-process-icons img.active-icon,
.process-icons img:hover,
.process-icons img.active-icon {
    opacity: 1;
}

.text-content {
    max-width: 400px;
}

.process-image {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    margin-right: -50px;
    padding-right: 30px;
}

.process-image-inner {
    display: flex;
    align-items: center;
    overflow: visible;
    position: relative;
}

.mainImage{
    height: 500px;
    width: auto;
    margin-right: 20px;
}

.next-arrow {
    width: 100px;
    height: auto;
    margin-left: 100px;
    z-index: 1;
    cursor: pointer;
}

.process-description{
    font-size: 1.5rem;
    width: 60%;
    max-width: 1500px;
    text-align: justify;
}

.process-description, .first-word, .background-text {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.transitioning {
    opacity: 0; 
}

.first-word {
    font-size: 8vw;
    display: block;
}

.background-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-30%, 40%) scale(1.5);
    font-size: 4rem;
    color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}
  

@media (max-width: 1270px) {
    .our-process-content {
        flex-direction: column-reverse; 
    }

    .process-description {
        width: 70%;
        max-width: none; 
    }

    .process-description P{
        font-size: 1.2rem;
    }

    .first-word {
        font-size: 3rem;
        z-index: 2;
    }
    
    .next-arrow{
        margin-left: 20px;
    }

    .process-image {
        display: flex;
        align-items: center;
        margin-left: auto;
        position: relative;
        overflow: hidden;
        margin-right: 0; 
        padding-right: 0px;
        width: 30%;
        float: right; 
    }

    .mainImage{
        height: 350px;
        max-height: 50vh;
        width: auto;
        margin-right: 20px;
    }
    
}

@media (max-width: 1015px) {
    .next-arrow{
        display: none;
    }
}

@media (max-width: 768px) {

    .process-icons {
        display: none;
    }

    .external-process-icons{
        flex-direction: row; 
        justify-content: center;
        width: 100%;
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .external-process-icons img {
        opacity: 0.5;
    }

    .external-process-icons span {
        height: auto;
        align-self: center; 
        margin-left: 0.8%;
        justify-content: center;
        height: 100%;
    }

    .external-process-icons span br{
        display: none;
    }
    
    .next-arrow{
        display: none;
        width: 0;
    }

    .mainImage{
        display: none;
        width: 0;
    }
    .background-text{
        display: none;
        width: 0;
    }

    .process-image{
        display: none;
        width: 0;
    }
    
    .process-description{
        width: 100%;
    }
}

@media (max-width: 541px){
    .mainImage{
        height: 180px;
    }
}