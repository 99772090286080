.contact-us {
    background-size: cover;
    background-position: center;
    text-align: center;
    justify-content: center;
    width: 100%;
}

.contact-us h2{
    text-align: center;
    font-size: 5rem;
}

.contact-us-content {
    width: 100%;
    margin-top: -386px;
    background-color: #fff;
}

.contact-us-content p {
    width: 30%;
    margin: 0 auto;
    text-align: center;
    min-width: 300px;
}

.contact-us-logo {
    max-width: 200px;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
    background-color: #eceff1;
    padding: 20px;
    width: 30%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    border: 2px solid grey;
    min-width: 300px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    color: black;
    opacity: 1;
}


.contact-form textarea {
    min-height: 150px;
}

.contact-form button {
    display: flex; 
    padding: 20px;
    margin: 20px auto;
    background-color: #039be5;
    color: white;
    border: none;
    cursor: pointer;
    width: 30%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 2% 0 2% 0 ;
    min-width: 300px;
    min-height: 100px;
}

.water-image img {
    width: 100%;
    height: auto;
    display: block;
}


.contact-form input::placeholder,
.contact-form textarea::placeholder {
  text-align: center;
}

.email-sent-message {
    margin-top: 20px;
    color: blue;
    font-size: 16px;
    margin-bottom: 20px;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-left-color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    justify-content: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

@media (max-width: 1250px) {
    .contact-us-content {
        width: 100%;
        margin-top: -300px;
        background-color: #fff;
    }
}

@media (max-width: 970px) {
    .contact-us-content {
        width: 100%;
        margin-top: -200px;
        background-color: #fff;
    }

    .contact-us h2{
        font-size: 4rem;
    }
}


@media (max-width: 645px) {
    .contact-us-content {
        width: 100%;
        margin-top: -80px;
        background-color: #fff;
    }
}
