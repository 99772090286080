.our-work {
    text-align: center;
    margin-top:0px;
    margin-bottom: 5%;
}

.our-work h2{
    text-align: center;
    color: #fff;
    font-size: 4rem;
}

.No-works{
    text-align: center;
    color: #fff;
    font-size: 1rem;
}

.work-gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
}

.work-item {
    position: relative;
    cursor: pointer;
}

.work-item img {
    width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
}

.work-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 10;
    width: 100%;
    min-width: 400px;
    max-width: 100%;
    min-height: 400px;
    max-height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;
    overflow-x: hidden;
    border-radius: 12px;
}

.work-info p,
.work-info h3 {
    color: black;
    word-wrap: break-word;
    padding: 0 10px;
    text-align: center;
    overflow-wrap: break-word;
    hyphens: auto;
}

.secondaryInfo{
    background-color: #fff;
    width: 100%;
    color: black;
}

.work-info.right {
    left: 100%;
    transform: translateX(0%);
}

.work-info.left {
    right: 100%;
    transform: translateX(-100%);
}

.image-item:hover .image-info,
.image-item:active .image-info {
  visibility: visible;
  opacity: 1;
}

.work-item:hover .work-info,
.work-item:active .work-info {
  visibility: visible;
  opacity: 1;
}

.primary-info {
    padding-top: 10px;
    width: auto;
}
  
.secondary-info { 
    padding-top: 10px;
    height: auto;
}

@media (max-width: 768px) {
    .work-gallery {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 480px) {
    .work-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 540px) {
    .work-info {
      min-width: 300px;
      padding: 10px;
      font-size: 0.8rem;
      max-width: 400px;
    }

    .work-info.right {
        left: 100%;
        transform: translateX(-40%);
    }
    
    .work-info.left {
        right: 100%;
        transform: translateX(-60%);
    }
  }