.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
  
.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 90%;

}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  height: 100%;
  cursor: pointer;
  background-color: transparent; 
  border: transparent;
}

.navbar-logo img {
  max-width: 100px;
  height: auto;
  max-height: 50px;
  width: auto;
}

.nav-links:hover {
  border-bottom: 2px solid #fff;
}

.language-switcher {
  display: flex;
  gap: 10px;
}
  
.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(to right, #3eabfe 0%, #a137fe 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    border: none;
  }
}