.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: linear-gradient(to right, #3eabfe 0%, #a137fe 100%);
    text-align: justify;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    color: white;
    max-height: 80vh;
    overflow: auto;
    position: relative;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.modal-header span{
    font-size: 3rem;
}

.button-close {
    background: none;
    border-radius: 20%;
    border-color: white;
    border-inline-color: white;
    border-style: solid;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    box-shadow: none;
}