.who-we-are {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.whoWeAreTitle {
  margin-bottom: 20px;
  color: #fff;
  font-size: 4rem;
}

.who-we-are p {
  font-size: 1.75rem;
  margin-left: 5%;
  text-align: justify;
}

.descriptionTitle{
  font-weight: 600;
  color: black;
  font-size: 2.4rem;
  margin: 0 0 4% 5%;

}

.who-we-are .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  width: 90%;
}

.who-we-are img {
  max-width: 60%;
  height: auto;
  width: auto;
  margin-right: 20px;
}

.who-we-are .text-content {
  max-width: 750px;
  width: 95%;
  text-align: left;
  padding-right: 5%;
}

.ourResultsText{
  background: none;
  border: none;
  color: #2596be;
  font-size: 1.5rem;
  padding: 0;
  cursor: pointer;
  margin-left: 5%;
}

@media (max-width: 2195px) {
  .who-we-are .text-content {
    max-width: 650px;
  }
  .who-we-are p {
    font-size: 1.25rem;
  }
  
  .descriptionTitle{
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .who-we-are .container {
    flex-direction: column;
  }

  .who-we-are img {
    max-width: 80%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .who-we-are .text-content {
    max-width: 100%;
    text-align: center;
  }

  .who-we-are h2 {
    font-size: 36px;
  }

  .descriptionTitle {
    font-size: medium;
  }
}
