.main-content{
    margin-top: 40px;
}

.main-content img{
    max-width: 100%;
    height: auto;
    max-height: 800px;
    width: auto;
}

.main-content .container > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.main-content p{
    color: #fff;
    font-size: 1.8rem;
}

.main-content .language-switcher {
    margin-left: 5%;
}
  
.main-content .talk-to-us {
    margin-right: 8%;
}
  
.talk-to-us {
    height: 40px; 
    display: flex;
    align-items: center;
}

.talk-to-us button{
    width: 100%;
    text-decoration: none;
    background-color: transparent; 
    border: transparent;
    cursor: pointer;
}

.talk-to-us button:hover {
    text-decoration: none;
}

.talk-to-us button p{
    text-decoration: none;
}

.talk-to-us img {
    height: 3%;
    position: absolute;
    padding-left: 1%;
}

.down-button{
    background-color: transparent; 
    border: transparent;
    cursor: pointer;
}

@media (max-width: 768px) {
    .main-content .language-switcher {
        margin-left: 10%; 
      }
      
    .main-content .talk-to-us {
        margin-right: 10%;
    }

    .talk-to-us p{
        font-size: 1rem;
    }
}
