.language-switcher button {
    background: none;
    border: none;
    color: rgba(130,189,252,255);
    cursor: pointer;
    padding: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    transition: color 0.3s;
}
  
.language-switcher button.active {
    color: white;
}
  
.language-switcher button:hover {
    color: white;
}
  