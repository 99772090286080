.what-we-do {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
}
 
.what-we-do h2 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 4rem;
}
  
.service-cards {
    display: flex;
    overflow-x: auto;
    gap: 40px;
    padding: 20px; 

}

.service-card {
    flex: none;
    width: 400px;
    min-height: 700px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background 0.3s;
}
.service-card h2 {
    font-size: 2rem;
    color: black;
    margin-left: 10px;
}
.service-card.active {
    background: white;
}

.service-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5%;
}

.service-card-arrows span {
    cursor: pointer;
}

.service-card-arrows {
    margin-right: 5%;
}

.service-card ul {
    list-style: none;
    padding: 0;
    margin-left: 5%;
}
  
.service-card li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 5%;
    margin-left: 5%;
}
  
.service-card li img {
    margin-right: 10px;
    width: 20px;

}

.service-card p {
    margin: 5%;
    text-align: justify;
}


@media (max-width: 1335px) {
    .service-cards {
        flex-direction: column;
        overflow-x: hidden;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .service-cards {
        display: flex;
        overflow-x: auto;
        gap: 0px;
        padding: 0px; 
        width: 100%;
        overflow: hidden;
    }
    .service-card {
        transform: scale(0.8);
        transform-origin: center;
    }
}

@media (max-width: 400px) {
    .service-card {
        transform: scale(0.7);
        transform-origin: center;
    }
}